<template>
  <div class="h-[calc(100vh-60px)] flex flex-col">
    <div
      class="sticky top-0 flex flex-col gap-2.5 bg-secondary-75 pb-5 z-[1000]">
      <div class="flex flex-col gap-2.5">
        <PVSkeleton v-if="isLoading" width="15rem" height="1rem"></PVSkeleton>
        <div
          v-else
          class="flex items-center gap-0.5 text-sm text-secondary-500 cursor-pointer"
          @click="$router.back()">
          <div class="flex items-center gap-0.5">
            <ChevronRight :size="16" />
            {{ t('course_settings.home') }}
          </div>
          /
          <p class="text-sm text-secondary-500">
            {{ course.caption || t('course_settings.new_content') }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <div v-if="isLoading" class="flex gap-1">
            <PVSkeleton width="10rem" height="2.2rem"></PVSkeleton>
            <PVSkeleton width="10rem" height="2.2rem"></PVSkeleton>
          </div>
          <div v-else class="relative">
            <PVSelectButton
              v-model="activeSettings"
              :pt="selectButtonPT"
              :options="Object.keys(settings)"
              :allow-empty="false"
              option-disabled="disabled">
              <template #option="{ option }">
                <div
                  class="relative flex gap-1.5 items-center"
                  @mouseleave="isDisabledHovered = false"
                  @mouseover="isDisabledHovered = !!option.disabled">
                  <component
                    :is="settings[option].icon"
                    :size="16"
                    :class="{
                      'stroke-secondary-800':
                        settings[option].type === activeSettings,
                      'stroke-secondary-450':
                        settings[option].type !== activeSettings,
                    }"/>
                  <p class="text-sm font-semibold text-secondary-800">
                    {{ settings[option].label }}
                  </p>
                </div>
              </template>
            </PVSelectButton>
          </div>

          <PVSkeleton
            v-if="isLoading"
            width="13rem"
            height="2.2rem"></PVSkeleton>
          <div v-else class="flex gap-2">
            <div v-if="course.id" class="flex gap-1.5">
              <PVButton
                v-if="course.published"
                v-tooltip.top="t('tooltips.play')"
                severity="neutral"
                class="!m-0 !p-1.5 !rounded-md !border-0 !bg-white h-8 w-8 shadow-sm"
                :disabled="isSaving || hasChanges"
                @click="openPreview">
                <Play
                  :size="16"
                  class="stroke-secondary-800"
                  :class="{ 'opacity-50': isSaving || hasChanges }"/>
              </PVButton>
              <div
                @mouseover="showErrors = true"
                @mouseout="showErrors = false">
                <PVButton
                  outlined
                  :disabled="isSaveDisabled"
                  class="!m-0 !py-1.5 border-primary text-primary"
                  @click="handleSaveWithRetro(false)">
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm leading-sm">
                      {{ t('course_settings.save_settings') }}
                    </span>
                  </div>
                </PVButton>
              </div>
              <div
                @mouseover="showErrors = true"
                @mouseout="showErrors = false">
                <PVButton
                  v-tooltip.top="t('tooltips.save_and_build')"
                  :disabled="isSaving || !isValid"
                  :loading="isSaving"
                  class="!m-0 !p-1.5 !rounded-md !border-0 h-8 w-8"
                  @click="handleSaveWithRetro(true)">
                  <template #icon>
                    <ArrowUpLeft :size="16" />
                  </template>
                </PVButton>
              </div>
            </div>
            <div
              v-else
              @mouseover="showErrors = true"
              @mouseout="showErrors = false">
              <PVButton
                :disabled="isSaving || !isValid || editingState.isAnyEditing"
                class="!m-0 !py-1.5"
                @click="saveCourse">
                <div class="flex items-center gap-1.5">
                  <span class="text-sm leading-sm">
                    {{ t('course_settings.create_course_go_edit') }}
                  </span>
                  <Sparkles :size="13" :class="{ 'animate-spin': isSaving }" />
                </div>
              </PVButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Red div filling remaining space and scrollable -->
    <div
      v-if="activeSettings === 'general'"
      class="flex-1 overflow-y-auto overflow-x-hidden no-scrollbar">
      <div
        class="flex flex-col gap-4"
        :class="{ 'pointer-events-none opacity-50': isSaving }">
        <ContentForm
          :course="course"
          :loading="isLoading"
          :can-edit="canEdit"/>

        <CourseCurriculum
          v-model:units="course.units"
          v-model:lessons="course.outline"
          :loading="isLoading"
          :can-edit="canEdit"
          :open-files-disabled="!isSaveDisabled || !courseId"
          :readonly="!canEdit"/>
      </div>
    </div>

    <div v-else class="flex-1 overflow-y-auto overflow-x-hidden no-scrollbar">
      <AdvancedSettings
        v-model:reset-retro-sections="resetRetroSections"
        :advanced-settings="course.settings"
        :course-not-inline="courseNotInline"
        :addons="course.addons"
        :panels="course.panels"
        :retro-state="retroState"
        :course-id="courseId"
        :can-edit="canEdit"
        :show-skills="true"
        @update:show-bot-data="setBotState"
        @update:show-panel-data="setPanelState"/>
    </div>
  </div>

  <BaseToast />
  <RetroConfirmationDialog
    :visible="showRetroConfirmation"
    :retro-values="retroValues"
    :hebrew-labels="hebrewLabels"
    @update:visible="showRetroConfirmation = $event"
    @confirm="saveCourse(shouldNavigate)"
    @cancel="showRetroConfirmation = false"/>
  <SuccessDialog
    :visible="showSuccessPopup"
    :header="t('course_settings.advanced_settings_texts.confirm_retro_changes')"
    :message="'נא להמתין לסיום הפעולה'"
    :animation-data="SuccessAnimation"
    @update:visible="showSuccessPopup = $event"/>
</template>
<script setup lang="ts">
import BaseToast from '/@/views/playground/components/BaseToast.vue';
import { computed, onMounted, ref, watch, provide, reactive } from 'vue';
import CourseCurriculum from '/@/views/Lobby/CourseSettings/CourseCurriculum.vue';
import ContentForm from '/@/views/Lobby/CourseSettings/ContentForm.vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchCourse } from '/@/app/services/courses';
import { selectButtonPT } from '/@/views/Lobby/styles';
import SuccessAnimation from '/@/assets/successAnimation.json';
import {
  Settings,
  Settings2,
  Play,
  Sparkles,
  ChevronRight,
  ArrowUpLeft,
} from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import PVButton from 'primevue/button';
import PVSelectButton from 'primevue/selectbutton';
import PVSkeleton from 'primevue/skeleton';
import { useEditorStore } from '/@/app/store/editor';
import {
  compareObjects,
  courseSchema,
  compareRules,
} from '/@/views/Lobby/utils';
import { generateObjectId } from '/@/utils';
import { useToast } from 'primevue/usetoast';
import { useAccountStore } from '/@/app/store/account';
import { useCourseStore } from '/@/app/store/course';

import { get, cloneDeep, isEqual } from 'lodash';
import { ICourse, OutlineItem } from '/@/app/types/interfaces';
import { useEditingTracker } from '/@/views/Lobby/CourseSettings/useEditingTracker';
import { AdvancedSettings } from '@amit/components';
import { useRouteQuery } from '@vueuse/router';
import { forceCourseRetroValues } from '/@/app/services/system';
import { RetroConfirmationDialog } from '@amit/components';
import { SuccessDialog } from '@amit/components';

const { t } = useI18n();
const route = useRoute();
const router = useRouter(); // Access the router instance

// Reactive meta to track the current meta dynamically
const currentMeta = reactive({ ...route.meta });

// Function to update or add meta dynamically
const addMeta = confirmLeave => {
  const currentRoute = router.currentRoute.value;

  // Clone and update meta
  const newMeta = {
    ...currentRoute.meta,
    confirmLeave,
    confirmLeaveTitle: t('course_settings.confirm_leave_tile'),
    confirmLeaveDescription: t('course_settings.confirm_leave_description'),
  };

  // Replace the route's meta with the updated object
  currentRoute.meta = newMeta;

  // Update the reactive `currentMeta` to reflect changes
  Object.assign(currentMeta, newMeta);
};
const toast = useToast();
const editorStore = useEditorStore();
const accountStore = useAccountStore();
const courseStore = useCourseStore();
const showSuccessPopup = ref(false);
const { editingState } = useEditingTracker();

const userId = computed(() => accountStore.user.id);

const originalObj = ref<Partial<ICourse>>({});
const initialUnit: OutlineItem = {
  _id: generateObjectId(),
  title: 'יחידה',
  type: 'unit',
};
const course = ref<Partial<ICourse>>({
  caption: '',
  description: '',
  units: [initialUnit],
  outline: [
    {
      id: generateObjectId(),
      title: 'נושא',
      type: 'lesson',
      unit: initialUnit._id,
    },
  ],
  cover:
    'https://res.cloudinary.com/dcodkxpej/image/upload/v1712662821/LessonsBackground/image-20240409-101444_pnudss.png',
  settings: {
    assessmentProfile: {
      id: '',
      title: '',
    },
    skills: [],
    optional: false,
    feedback_enabled: true,
    gradable: true,
    attempts: 3,
  },
  addons: [],
  panels: [],
  locale: 'he',
});

const retroState = ref<Record<string, boolean>>({
  optional: false,
  feedback_enabled: false,
  gradable: false,
  attempts: false,
});

const updateBots = ref(false);
const updatePanels = ref(false);

const isRetroChanged = ref(false);
const retroValues = ref<Partial<{ addons: boolean; panels: boolean }>>({});

const addonsChanged = computed(
  () => !isEqual(course.value.addons, originalObj.value.addons),
);
const panelsChanged = computed(
  () => !isEqual(course.value.panels, originalObj.value.panels),
);

watch(
  [retroState, addonsChanged, panelsChanged],
  ([newRetroState, newAdonsChanged, newPanelsChanged]) => {
    isRetroChanged.value =
      Object.values(newRetroState).some(value => value === true) ||
      newAdonsChanged ||
      newPanelsChanged;
  },
  { deep: true },
);

const isPublished = computed(() => course.value.published);
const courseId = ref<string>(route.params.course as string);

const isLoading = ref(false);
const isSaving = ref(false);
const isDisabledHovered = ref(false);
const settings = {
  general: {
    label: t('course_settings.general_settings'),
    icon: Settings,
    type: 'general',
  },
  advanced: {
    label: t('course_settings.advanced_settings'),
    icon: Settings2,
    type: 'advanced',
  },
};

const activeSettings = useRouteQuery<string>('settings', 'general');

const fetchContent = courseId => {
  isLoading.value = true;
  const { onSuccess: onCourseFetched } = fetchCourse(courseId);

  onCourseFetched(({ data }) => {
    isLoading.value = false;
    course.value = data;

    if (!course.value.addons) {
      course.value.addons = [];
    }

    if (!course.value.panels) {
      course.value.panels = [];
    }

    if (course.value.panels?.length > 0) {
      course.value.panels = course.value.panels.map(panel => {
        return {
          ...panel,
          status: 'valid',
          loading: false,
        };
      });
    }

    originalObj.value = cloneDeep(course.value);

    resetRetroSections.value = false;
  });
};

const canEdit = computed(() => {
  if (!course.value.id) {
    return true;
  }
  const isCreator = get(course.value, 'created_by.id') === userId.value;

  const hasFullAccess = get(course.value, 'contributors', []).some(
    c =>
      get(c, 'user')?.id === userId.value && get(c, 'access') === 'FULL_ACCESS',
  );
  return isCreator || hasFullAccess;
});

const courseNotInline = computed(
  () => courseId.value && course.value.settings.submissionStyle !== 'inline',
);

const hasErrors = async course => {
  try {
    await courseSchema.validate(course, { abortEarly: false });
    return false; // No errors
  } catch (err) {
    const errors = err.inner.reduce((acc, error) => {
      acc[error.path] = error.message;
      return acc;
    }, {});
    return errors;
  }
};

const showRetroConfirmation = ref(false);
const hebrewLabels = computed(() => ({
  optional: t('course_settings.advanced_settings_texts.optional'),
  feedback_enabled: t(
    'course_settings.advanced_settings_texts.immediate_feedback',
  ),
  gradable: t('course_settings.advanced_settings_texts.gradable'),
  attempts: t('course_settings.advanced_settings_texts.attempts'),
  addons: t('course_settings.advanced_settings_texts.addons'),
  panels: t('course_settings.advanced_settings_texts.panels'),
}));

const resetRetroSections = ref(false);

// Add ref to store navigation state
const shouldNavigate = ref(false);

const handleSaveWithRetro = (navigate = false) => {
  if (
    isRetroChanged.value ||
    (updateBots.value && addonsChanged.value) ||
    (panelsChanged.value && updatePanels.value)
  ) {
    // Calculate retroValues before showing the popup
    retroValues.value = Object.entries(retroState.value)
      .filter(([_, value]) => value === true)
      .reduce((acc, [key]) => {
        if (course.value.settings && course.value.settings[key] !== undefined) {
          acc[key] = course.value.settings[key];
        }
        return acc;
      }, {});

    if (addonsChanged.value) {
      retroValues.value.addons = true;
    }

    if (panelsChanged.value) {
      retroValues.value.panels = true;
    }

    shouldNavigate.value = navigate; // Store the navigation state
    showRetroConfirmation.value = true;
    return;
  }

  // If no retro changes, proceed with normal save
  saveCourse(navigate);
};

const setBotState = (value: boolean) => {
  updateBots.value = value;
};

const setPanelState = (value: boolean) => {
  updatePanels.value = value;
};

// Modify saveCourse to remove retro check
const saveCourse = async (navigate = true) => {
  if (navigate && !canEdit.value && course.value.id) {
    editorStore.navigateToEditCourse(course.value.id);
    return;
  }
  showRetroConfirmation.value = false;
  isSaving.value = true;
  showSuccessPopup.value = true;
  course.value.total_lessons = get(course.value, 'outline', []).length;
  course.value.panels = course.value.panels.filter(
    panel => panel.status === 'success' || panel.status === 'valid',
  );

  course.value.updateBots = updateBots.value && addonsChanged.value;
  course.value.updatePanels = updatePanels.value && panelsChanged.value;

  try {
    const courseIdLocal = await editorStore.createOrEditCourse(course.value);
    courseId.value = courseIdLocal as string;

    if (isRetroChanged.value) {
      await forceCourseRetroValues({
        courseId: courseId.value,
        settings: retroValues.value,
      });
      isRetroChanged.value = false;
      retroValues.value = {};
      retroState.value = {
        optional: false,
        feedback_enabled: false,
        gradable: false,
        attempts: false,
      };
    }
    resetRetroSections.value = true;
    fetchContent(courseIdLocal);
    await courseStore.fetchCourseFiles(courseId.value);
    await new Promise(resolve => setTimeout(resolve, 1000));
    showSuccessPopup.value = false;
    toast.add({
      severity: 'success',
      summary: `הקורס ${course.value.id ? 'עודכן' : 'נוצר'} בהצלחה`,
      life: 4000,
      position: 'top-right',
    });
    if (navigate) editorStore.navigateToEditCourse(courseIdLocal);
  } catch (error) {
    console.error('Failed to create or edit course:', error);
    showSuccessPopup.value = false;
  }
  isSaving.value = false;
};

onMounted(() => {
  if (route.params.course) {
    courseId.value = route.params.course as string;
    fetchContent(route.params.course);
  }
});

const hasChanges = ref(false);
const isValid = ref(false);
const errors = ref([]);
const showErrors = ref(false);
watch(
  () => [
    course.value,
    originalObj.value,
    isRetroChanged.value,
    activeSettings.value,
  ],
  async () => {
    hasChanges.value = compareObjects(
      course.value,
      originalObj.value,
      compareRules,
    );
    addMeta(hasChanges.value || isRetroChanged.value);

    errors.value = await hasErrors(course.value);
    console.log(errors.value);
    isValid.value = !errors.value;
  },
  { deep: true },
);

const openPreview = () => {
  const routeData = router.resolve({
    name: 'preview',
    params: {
      course: course.value.id,
      lesson: get(course.value, 'outline[0].id', null),
    },
  });

  window.open(routeData.href, '_blank');
};

const isSaveDisabled = computed<boolean>(
  () =>
    isSaving.value ||
    !isValid.value ||
    (!hasChanges.value && !isRetroChanged.value) ||
    !canEdit.value ||
    editingState.isAnyEditing,
);

provide('isCoursePublished', isPublished);
provide('errors', errors);
provide('showErrors', showErrors);
</script>

<style lang="scss">
.content:has(.course-settings) {
  > div {
    overflow: inherit;
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0) rotate(-12deg);
  }
  20%,
  60% {
    transform: translate(1px, -1px) rotate(-12deg);
  }
  40%,
  80% {
    transform: translate(-1px, 1px) rotate(-12deg);
  }
}

.animate-shake {
  animation: shake 0.4s ease-in-out;
}
</style>
