<template>
  <PVDialog
    :visible="visible"
    :modal="true"
    :header="header"
    class="!w-[380px]"
    @update:visible="updateVisible">
    <div class="flex flex-col items-center gap-2">
      <span class="text-sm text-secondary-900">{{ message }}</span>
      <LottieAnimation
        :animation-data="animationData"
        :height="140"
        :width="140"
        loop/>
    </div>
  </PVDialog>
</template>

<script setup lang="ts">
import PVDialog from 'primevue/dialog';
import { LottieAnimation } from 'lottie-web-vue';

defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  header: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  animationData: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:visible']);

const updateVisible = (value: boolean) => {
  emit('update:visible', value);
};
</script>
