<template>
  <ToggleSection
    v-model="showPanelData"
    :title="t('course_settings.advanced_settings_texts.add_site')"
    :description="t('course_settings.advanced_settings_texts.add_site_text')"
    :show-content="showPanelData">
    <div
      v-if="showPanelData"
      class="mt-3 flex flex-col items-start gap-3 w-full">
      <div
        v-for="(panel, index) in panels"
        :key="index"
        class="w-full flex flex-col items-start gap-2">
        <span class="font-normal text-xs text-secondary-900">
          {{ t('course_settings.advanced_settings_texts.url') }}
        </span>
        <div
          class="flex items-center w-full gap-3"
          :class="{
            'text-red-600': panel.status === 'error',
            'text-green-600': panel.status === 'success',
            'text-secondary-900': !panel.status || panel.status === 'valid',
          }">
          <CircleMinus
            v-if="!panel.status"
            :size="16"
            class="cursor-pointer"
            @click="removePanel(index)"/>
          <IconSelect :icon="panel.icon" @update="setIcon($event, index)" />
          <div
            class="flex flex-col gap-2 w-full relative"
            :class="{ 'mb-2': panel.errorMessage }">
            <BaseInput
              v-model="panel.blocks[0].content.src"
              :error="panel.status === 'error'"
              placeholder="https://example.com"
              @input="event => updatePanelUrl(panel)"
              @keydown.enter="validatePanelUrl(index)">
              <template #suffix>
                <ProgressSpinner
                  v-if="panel.loading"
                  :style="{ height: '1.5rem', width: '1.5rem' }"/>
                <div v-else>
                  <CircleArrowLeft
                    v-if="!panel.status"
                    :class="{
                      'stroke-secondary-450 cursor-not-allowed':
                        !panel.blocks[0].content.src,
                      'stroke-secondary-900 cursor-pointer':
                        panel.blocks[0].content.src,
                    }"
                    :size="16"
                    @click="validatePanelUrl(index)"/>
                  <CircleX
                    v-else
                    class="cursor-pointer"
                    :size="16"
                    @click="removePanel(index)"/>
                </div>
              </template>
            </BaseInput>
            <div
              v-if="panel.errorMessage"
              class="absolute right-[1rem] bottom-[-1rem] text-xs text-red-600">
              {{ panel.errorMessage }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="panels.length < 6"
        class="cursor-pointer flex items-center justify-start gap-2 w-full py-3 px-4 border-1 border-solid border-secondary-300 rounded-md"
        @click="addEmptyPanel">
        <div
          class="h-8 w-8 bg-primary-200 rounded-full flex items-center justify-center">
          <Plus :size="12" />
        </div>
        <p class="text-secondary-900 text-sm font-semibold">
          {{ t('course_settings.advanced_settings_texts.add_panel') }}
        </p>
      </div>
    </div>
  </ToggleSection>
</template>

<script setup lang="ts">
import { ToggleSection } from '@amit/components';
import { ref, onMounted, watch, nextTick } from 'vue';
import { useVModel } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { IPanel } from '@/types/interfaces';
import { Plus, CircleArrowLeft, CircleX, CircleMinus } from 'lucide-vue-next';
import BaseInput from '@/components/Input/BaseInput.vue';
import ProgressSpinner from 'primevue/progressspinner';
import IconSelect from './IconSelect.vue';
import * as linkify from 'linkifyjs';
import { checkIframeEmbed } from '@amit/composables/src/utils';

const { t } = useI18n();

const props = defineProps({
  panels: {
    type: Array as () => IPanel[],
    required: true,
  },
});

const emit = defineEmits([
  'update:panels',
  'update:hasErrors',
  'update:showPanelData',
]);

const panels = useVModel(props, 'panels', emit);

const showPanelData = ref(false);

const panelTemplate = {
  type: 'content',
  name: 'bible',
  icon: 'MenuSquare',
  blocks: [
    {
      name: 'embed',
      type: 'content',
      content: {
        type: 'website',
        src: '',
        height: '100%',
        width: '100%',
      },
    },
  ],
};

const isValidUrl = async (url: string) => {
  return linkify.test(url);
};

const isFullUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

const canDisplayInIframe = async (url: string): Promise<boolean> => {
  return new Promise(resolve => {
    const { onSuccess, onError } = checkIframeEmbed(url);

    onSuccess(({ data }) => {
      resolve(data.status);
    });

    onError(error => {
      console.error(error);
      resolve(false);
    });
  });
};

const checkForErrors = () => {
  hasErrors.value = panels.value.some(panel => panel.status === 'error');
  emit('update:hasErrors', hasErrors.value);
};

const validatePanelUrl = async (index: number) => {
  const panel = panels.value[index];
  panel.loading = true;
  panel.status = '';

  const url = panel.blocks[0].content.src;

  if (!url) {
    panel.loading = false;
    panel.status = 'error';
    panel.errorMessage = t(
      'course_settings.advanced_settings_texts.empty_url_error',
    );
    checkForErrors();
    return;
  }

  const isValid = await isValidUrl(url);

  if (!isValid) {
    panel.loading = false;
    panel.status = 'error';
    panel.errorMessage = t(
      'course_settings.advanced_settings_texts.invalid_url',
    );
    checkForErrors();
    return;
  }

  const isFull = isFullUrl(url);

  if (!isFull) {
    panel.loading = false;
    panel.status = 'error';
    panel.errorMessage = t(
      'course_settings.advanced_settings_texts.non_https_url_warning',
    );
    checkForErrors();
    return;
  }

  const isIframeValid = await canDisplayInIframe(url);
  panel.loading = false;

  if (isIframeValid) {
    panel.status = 'success';
    panel.errorMessage = '';
  } else {
    panel.status = 'error';
    panel.errorMessage = t(
      'course_settings.advanced_settings_texts.invalid_iframe',
    );
    checkForErrors();
  }
};

const addEmptyPanel = () => {
  panels.value.push({
    ...panelTemplate,
    icon: 'Map',
    blocks: [
      {
        ...panelTemplate.blocks[0],
        content: {
          ...panelTemplate.blocks[0].content,
          src: '',
        },
      },
    ],
    status: '',
    loading: false,
  });
};

const removePanel = (index: number) => {
  panels.value.splice(index, 1);
  checkForErrors();
};

const hasErrors = ref(false);

onMounted(async () => {
  await nextTick();
  if (panels.value.length > 0) {
    showPanelData.value = true;
    panels.value.forEach(panel => {
      panel.status = 'valid';
      panel.loading = false;
    });
  }
});

watch(
  () => panels.value,
  () => {
    if (panels.value.length > 0) {
      showPanelData.value = true;
    }
  },
);

const setIcon = (icon: string, index: number) => {
  panels.value[index].icon = icon;
};

const updatePanelUrl = (panel: IPanel) => {
  panel.status = '';
};

watch(showPanelData, () => {
  emit('update:showPanelData', showPanelData.value);
});
</script>
