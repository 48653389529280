<template>
  <ToggleSection
    v-model="showBotData"
    :title="t('course_settings.advanced_settings_texts.add_bot')"
    :description="t('course_settings.advanced_settings_texts.add_bot_text')"
    :show-content="showBotData">
    <div v-if="showBotData" class="flex flex-col items-center gap-2 mt-3">
      <div
        v-for="bot in fetchedBots"
        :key="bot.name"
        class="w-full flex flex-col items-center">
        <div
          v-if="bot.isTemporary"
          class="flex flex-col gap-2 items-start w-full">
          <span class="text-xs text-secondary-900">
            {{ t('course_settings.advanced_settings_texts.bot_id') }}
          </span>
          <div
            class="flex items-center w-full gap-3 relative"
            :class="{
              'text-red-600': bot.status === 'error',
              'mb-2': bot.errorMessage,
            }">
            <CircleMinus
              v-if="!bot.status"
              :size="16"
              class="cursor-pointer"
              @click="removeBot(bot.id)"/>
            <BaseInput
              v-model="bot.id"
              :error="bot.status === 'error'"
              @keydown.enter="addBot(bot)">
              <template #suffix>
                <ProgressSpinner
                  v-if="bot.loading"
                  :style="{ height: '2rem', width: '2rem' }"/>
                <button
                  v-else-if="bot.status === 'error'"
                  @click="removeBot(bot.id)">
                  <CircleX :size="16" />
                </button>
                <CircleArrowLeft
                  v-else
                  :class="{
                    'stroke-secondary-450 cursor-not-allowed': !bot.id,
                    'stroke-secondary-900 cursor-pointer': bot.id,
                  }"
                  :size="16"
                  @click="addBot(bot)"/>
              </template>
            </BaseInput>
            <div
              v-if="bot.errorMessage"
              class="absolute right-[1rem] bottom-[-1rem] text-xs text-red-600">
              {{ bot.errorMessage }}
            </div>
          </div>
        </div>
        <div v-else class="w-full flex flex-col gap-3">
          <div class="flex flex-col items-start gap-2">
            <span class="text-xs font-normal">
              {{ t('course_settings.advanced_settings_texts.bot_id') }}
            </span>
            <div
              class="flex items-center justify-between px-4 py-[0.625rem] w-full border-1 border-solid border-secondary-300 text-secondary-800 rounded-sm">
              <span
                class="font-normal text-base"
                :class="{
                  'text-green-600': bot.status === 'success',
                  'text-red-600': bot.status === 'error',
                  'text-secondary-800': !bot.status,
                }">
                {{ bot.id }}
              </span>
              <ProgressSpinner
                v-if="bot.loading"
                :style="{ height: '2rem', width: '2rem' }"/>
              <button v-else @click="removeBot(bot.id)">
                <CircleX :size="16" />
              </button>
            </div>
          </div>
          <div class="flex items-center w-full">
            <div class="flex flex-col items-start w-full gap-2">
              <span class="text-xs font-normal">
                {{ t('course_settings.advanced_settings_texts.bot_name') }}
              </span>
              <div
                class="w-full rounded-sm flex items-center justify-between border-1 order-solid border-secondary-300 px-4 py-[0.625rem]">
                <span class="font-normal text-base text-secondary-800">
                  {{ bot.name }}
                </span>
                <img class="h-6 w-6 rounded-full" :src="bot.image" alt="bot" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="fetchedBots.length < 6"
        class="cursor-pointer flex items-center justify-start gap-2 w-full py-3 px-4 border-1 border-solid border-secondary-300 rounded-md"
        @click="addEmptyBot">
        <div
          class="h-8 w-8 bg-primary-200 rounded-full flex items-center justify-center">
          <Plus :size="12" />
        </div>
        <p class="text-secondary-900 text-sm font-semibold">
          {{ t('course_settings.advanced_settings_texts.add_another_bot') }}
        </p>
      </div>
    </div>
  </ToggleSection>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseInput from '@/components/Input/BaseInput.vue';
import { useVModel } from '@vueuse/core';
import { ToggleSection } from '@amit/components';
import { Plus, CircleArrowLeft, CircleX, CircleMinus } from 'lucide-vue-next';
import ProgressSpinner from 'primevue/progressspinner';
import { IBot } from 'packages/components/types/interfaces';

const { t } = useI18n();

const props = defineProps({
  addons: {
    type: Array as () => IBot[],
    required: true,
  },
  isLesson: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:hasErrors',
  'update:showBotData',
]);

const addons = useVModel(props, 'addons', emit);

const BOT_SERVER_URL = 'https://amit-bots.com';
const showBotData = ref(false);

interface BotItem {
  id: string;
  name: string;
  supportedResponseTypes: string[];
  image: string;
  idleVideo: string;
  isTemporary: boolean;
  status: string;
  loading: boolean;
  errorMessage?: string;
}

const fetchedBots = ref<BotItem[]>([]);

const botTemplate = {
  settings: {
    recognition_lang: 'en-us',
    backgroundColor: '#575754',
    botId: '',
    level: 'A1',
  },
  type: 'assistant',
};

const fetchBot = async (botId: string) => {
  try {
    const response = await fetch(`${BOT_SERVER_URL}/bots/${botId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { ...data, id: botId };
  } catch (error) {
    console.error('Error fetching bot:', error);
    throw error;
  }
};

const initializeFetchedBots = async () => {
  fetchedBots.value = [];
  const addonsSnapshot = [...addons.value];
  for (const addon of addonsSnapshot) {
    const bot = await fetchBot(addon.settings.botId);
    if (bot) {
      bot.isTemporary = false;
      fetchedBots.value.push({
        id: addon.settings.botId,
        ...bot,
        loading: false,
        status: '',
      });
    }
  }
};

const addEmptyBot = () => {
  fetchedBots.value.push({
    id: '',
    name: '',
    supportedResponseTypes: [],
    image: '',
    idleVideo: '',
    isTemporary: true,
    status: '',
    loading: false,
  });
};

const addBot = async (botItem: BotItem) => {
  hasErrors.value = false;

  const botId = botItem.id;

  botItem.loading = true;
  const addonCount = fetchedBots.value.filter(bot => bot.id === botId).length;
  if (addonCount > 1) {
    botItem.loading = false;
    botItem.status = 'error';

    botItem.id = 'id-error';
    fetchedBots.value = fetchedBots.value.filter(bot => bot.id !== 'id-error');

    botItem.errorMessage = t(
      'course_settings.advanced_settings_texts.duplicate_bot_error',
    );

    return;
  }
  checkForErrors();
  try {
    const bot = await fetchBot(botId);
    if (bot) {
      addons.value.push({
        ...botTemplate,
        settings: { ...botTemplate.settings, botId },
      });
      bot.loading = false;
      bot.status = 'success';
      const botIndex = fetchedBots.value.findIndex(
        fetchedBot => fetchedBot.id === botId,
      );
      if (botIndex !== -1) {
        fetchedBots.value.splice(botIndex, 1, {
          ...bot,
          isTemporary: false,
        });
      }
    } else {
      checkForErrors();
    }
  } catch (error) {
    console.error('Error adding bot:', error);
    botItem.loading = false;
    botItem.status = 'error';
    botItem.errorMessage = t(
      'course_settings.advanced_settings_texts.fetch_bot_error',
    );
  }
};

const removeBot = (botId: string) => {
  const addonIndex = addons.value.findIndex(
    addon => addon.settings.botId === botId,
  );
  if (addonIndex !== -1) {
    addons.value.splice(addonIndex, 1);
  }

  const botIndex = fetchedBots.value.findIndex(bot => bot.id === botId);
  if (botIndex !== -1) {
    fetchedBots.value.splice(botIndex, 1);
  }
  checkForErrors();
};

const hasErrors = ref(false);

const checkForErrors = () => {
  hasErrors.value = fetchedBots.value.some(bot => bot.status === 'error');
  emit('update:hasErrors', hasErrors.value);
};

onMounted(() => {
  initializeFetchedBots();
  if (addons.value.length > 0) {
    showBotData.value = true;
  }
});

watch(
  () => addons.value,
  () => {
    if (!props.isLesson) {
      initializeFetchedBots();
    }
    if (addons.value.length > 0) {
      showBotData.value = true;
    }
  },
);

watch(showBotData, () => {
  emit('update:showBotData', showBotData.value);
});
</script>
