import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export function searchSchools() {
  return useApi(http.get('/system/schools'));
}

export function loadConfig() {
  return useApi(http.get('/system/config'));
}

export function setSystemConfig(currentYear) {
  return useApi(http.post('/system/config', { currentYear }));
}

export const forceCourseRetroValues = (payload: {
  courseId: string;
  settings: Record<string, boolean>;
}) => {
  const retroPayload = {
    action: 'content/force-course-settings',
    payload,
  };
  return useApi(http.post('/system/invoke', retroPayload));
};
