<template>
  <div class="w-full flex flex-col items-center gap-4">
    <div
      v-for="key in ['optional', 'feedback_enabled', 'gradable', 'attempts']"
      :key="key"
      class="w-full">
      <ToggleSection
        :model-value="getToggleValue(key)"
        :title="basicSettingsMap[key]?.title"
        :description="basicSettingsMap[key]?.description"
        :show-content="openedSections.has(key)"
        :disabled="courseNotInline"
        @update:model-value="value => handleToggleChange(key, value)">
        <div v-if="!isNewCourse && openedSections.has(key)">
          <div class="flex items-center gap-2 py-2">
            <PVRadioButton v-model="retroState[key]" :value="false" />
            <span class="text-sm font-normal text-secondary-900">
              {{ t('course_settings.advanced_settings_texts.apply_forward') }}
            </span>
          </div>
          <div class="flex items-center gap-2 py-2">
            <PVRadioButton v-model="retroState[key]" :value="true" />
            <span class="text-sm font-normal text-secondary-900">
              {{ t('course_settings.advanced_settings_texts.apply_retro') }}
            </span>
          </div>
        </div>
      </ToggleSection>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import PVRadioButton from 'primevue/radiobutton';
import { useVModel } from '@vueuse/core';
import { ToggleSection } from '@amit/components';
import type { ICourseSettings } from 'packages/components/types/interfaces';

const { t } = useI18n();

const props = defineProps({
  settingsModel: {
    type: Object as PropType<ICourseSettings>,
    required: true,
  },
  retroState: {
    type: Object as () => Record<string, boolean>,
    required: true,
  },
  isNewCourse: {
    type: Boolean,
    required: true,
  },
  resetRetroSections: {
    type: Boolean,
    required: false,
    default: false,
  },
  courseNotInline: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'update:resetRetroSections']);

const settingsModel = useVModel(props, 'settingsModel', emit);
const retroState = useVModel(props, 'retroState', emit);

const openedSections = ref(new Set());

const basicSettingsMap = {
  optional: {
    title: t('course_settings.advanced_settings_texts.optional'),
    description: t('course_settings.advanced_settings_texts.optional_text'),
  },
  feedback_enabled: {
    title: t('course_settings.advanced_settings_texts.immediate_feedback'),
    description: t(
      'course_settings.advanced_settings_texts.immediate_feedback_text',
    ),
  },
  gradable: {
    title: t('course_settings.advanced_settings_texts.gradable'),
    description: t('course_settings.advanced_settings_texts.gradable_text'),
  },
  attempts: {
    title: t('course_settings.advanced_settings_texts.attempts'),
    description: t('course_settings.advanced_settings_texts.attempts_text'),
  },
};

watch(
  () => props.resetRetroSections,
  newVal => {
    if (newVal) {
      openedSections.value = new Set();
      emit('update:resetRetroSections', false);
    }
  },
);

const getToggleValue = (key: string): boolean => {
  if (key === 'attempts') {
    return settingsModel.value[key] === 3;
  }
  if (key === 'optional') {
    return !settingsModel.value[key];
  }
  return settingsModel.value[key];
};

const handleToggleChange = (key: string, value: boolean) => {
  if (!props.isNewCourse) {
    openedSections.value.add(key);
  }
  // Always add to openedSections

  // Handle max_attempts specially
  if (key === 'attempts') {
    settingsModel.value[key] = value ? 3 : 1;
  } else if (key === 'optional') {
    settingsModel.value[key] = !value;
  } else {
    settingsModel.value[key] = value;
  }

  // Only reset retroState when toggle is turned off
  if (!value) {
    retroState.value[key] = false;
  }
};
</script>
