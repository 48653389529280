<template>
  <PVDialog
    v-model:visible="isVisible"
    :modal="true"
    :closable="true"
    :header="t('course_settings.advanced_settings_texts.confirm_retro_changes')"
    class="w-[90vw] max-w-[500px]">
    <div class="flex flex-col gap-6">
      <div class="text-sm font-simplerBold font-semibold text-secondary-900">
        {{
          t(
            'course_settings.advanced_settings_texts.confirm_retro_changes_title',
          )
        }}
      </div>
      <div
        v-for="(_value, key) in retroValues"
        :key="key"
        class="flex flex-col gap-2">
        <div class="text-sm text-secondary-900 font-normal">
          {{ hebrewLabels[key] }} -
          {{
            key === 'addons' || key === 'panels'
              ? t(
                  'course_settings.advanced_settings_texts.retro_true_addons_panels',
                )
              : t('course_settings.advanced_settings_texts.retro_true')
          }}
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end gap-3 px-2">
        <PVButton
          severity="neutral"
          label="החלת הגדרה"
          outlined
          :pt="{
            label: {
              class: '!font-simplerBold',
            },
          }"
          class="!border-secondary-900 !text-secondary-900 !font-simplerBold !text-sm hover:text-secondary-800 hover:bg-secondary-100"
          @click="onConfirm"/>
        <PVButton
          label="התחרטתי"
          outlined
          severity="light"
          :pt="{
            label: {
              class: '!font-simplerBold',
            },
          }"
          class="!font-simplerBold !text-sm hover:text-primary-500"
          @click="onCancel"/>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import { computed } from 'vue';

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  retroValues: {
    type: Object,
    required: true,
  },
  hebrewLabels: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:visible', 'confirm', 'cancel']);

const { t } = useI18n();

const isVisible = computed({
  get: () => props.visible,
  set: value => {
    if (!value) {
      onCancel();
    }
  },
});

const onConfirm = () => {
  emit('confirm');
};

const onCancel = () => {
  emit('update:visible', false);
  emit('cancel');
};
</script>
