<template>
  <div class="w-full">
    <label for="multiple-ac-2" class="text-secondary-900 text-xs mb-2 block">
      {{ t('course_settings.advanced_settings_texts.course_skills') }}
    </label>
    <PVAutoComplete
      v-model="skillsModel"
      input-id="multiple-ac-2"
      multiple
      fluid
      :suggestions="filteredSkills"
      :typeahead="false"
      :pt="{
        pcchip: {
          root: ({ props, context }) => ({
            class: [
              // Keep all default classes from the preset
              'inline-flex items-center flex-row-reverse',
              'px-2 mx-0 my-1 ml-2',
              'rounded-full',
              'text-xs cursor-default',
              // Override only the colors
              'bg-secondary-100',
              'text-secondary-800',
            ],
          }),
        },
      }"
      @item-select="handleItemSelect"
      @before-input="handleBeforeInput"/>
    <div
      class="flex items-center justify-end my-2 text-xs font-normal text-[#9BAABF]">
      <span>20</span>
      <span>/</span>
      <span>{{ skillsModel?.length ?? 0 }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import PVAutoComplete from 'primevue/autocomplete';
import { computed } from 'vue';

const props = defineProps<{
  skills: string[];
  filteredSkills: string[];
}>();

const emit = defineEmits<{
  'update:skills': [skills: string[]];
}>();

const { t } = useI18n();

const skillsModel = computed({
  get: () => props.skills,
  set: newValue => {
    const validSkills = (newValue || []).filter(
      skill => skill && typeof skill === 'string' && skill.trim().length > 0,
    );

    const limitedSkills = validSkills.slice(0, 20);

    emit('update:skills', limitedSkills);
  },
});

const handleItemSelect = (event: { value: string }) => {
  if (!event.value || typeof event.value !== 'string' || !event.value.trim()) {
    return;
  }
};

const handleBeforeInput = (event: InputEvent) => {
  if (!(event.target as HTMLInputElement).value.trim()) {
    event.preventDefault();
  }
};
</script>
