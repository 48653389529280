<template>
  <div
    class="flex p-4 rounded"
    :class="{
      'border-1 border-secondary-250': !noBorder,
      'items-center justify-between': labelPlacement === 'justify-between',
      'items-center gap-2': labelPlacement === 'start',
      'items-center flex-row-reverse justify-end gap-2':
        labelPlacement === 'end',
    }">
    <p class="text-sm text-secondary-900">{{ label }}</p>
    <button
      class="flex w-[28px] relative bg-secondary-250 h-[5px] rounded-full transition-all duration-300"
      :class="{
        '!cursor-not-allowed !bg-secondary-450': disabled,
      }"
      :disabled="disabled"
      @click="checked = !checked">
      <span
        class="circle absolute -top-[4px] left-0 flex shadow-sm rounded-full w-[13px] h-[13px] transition-all duration-300"
        :class="{
          'left-0 bg-secondary-900': !checked,
          'left-4 !bg-primary-600': checked,
          '!bg-secondary-500': disabled,
        }"
        aria-hidden="true"></span>
    </button>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  label: {
    type: String,
    default: '',
  },
  labelPlacement: {
    type: String,
    default: 'justify-between',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
});

const checked = defineModel<boolean>();
</script>
