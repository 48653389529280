<template>
  <div class="bg-white p-5 relative">
    <div
      v-if="!canEdit"
      class="absolute inset-0 z-10 cursor-not-allowed"
      @click.prevent
      @click.stop></div>
    <div class="flex justify-between items-center mb-3">
      <h1 class="font-simplerBold text-lg">{{ t('features.title') }}</h1>
      <slot name="save-button"></slot>
    </div>

    <div v-if="isLesson" class="text-sm text-secondary-900 mb-3">
      הגדרות ברירות מחדל, אשר חלות על כל הנושא
    </div>
    <div class="flex gap-4">
      <div class="flex-1 flex flex-col gap-4">
        <div v-if="!isLesson" class="w-full">
          <h3 class="text-sm font-semibold text-secondary-900">
            {{ t('course_settings.advanced_settings_texts.default_assesment') }}
          </h3>
          <span class="text-sm text-secondary-900">
            {{
              t(
                'course_settings.advanced_settings_texts.default_assesment_text',
              )
            }}
          </span>
        </div>
        <CourseSkills
          v-if="showSkills"
          :skills="settingsModel.skills"
          :filtered-skills="filteredSkills"
          @update:skills="newSkills => (settingsModel.skills = newSkills)"/>
        <RetroSettings
          v-model:reset-retro-sections="resetRetroSections"
          :settings-model="settingsModel"
          :course-not-inline="courseNotInline"
          :retro-state="retroState"
          :is-new-course="isNewCourse"/>
      </div>
      <div class="flex-1 flex flex-col gap-4">
        <div v-if="!isLesson">
          <h3 class="text-sm font-semibold text-secondary-900">
            {{ t('course_settings.advanced_settings_texts.default_conent') }}
          </h3>
          <span class="text-sm font-normal text-secondary-800">
            {{
              t('course_settings.advanced_settings_texts.default_conent_text')
            }}
          </span>
        </div>
        <BotSettings
          :addons="addons"
          :is-lesson="isLesson"
          @update:show-bot-data="emitBotState"
          @update:has-errors="handleBotErrors"/>
        <PanelSettings
          :panels="panels"
          @update:show-panel-data="emitPanelState"
          @update:has-errors="handlePanelErrors"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { IAdvancedSettings, IPanel } from '@/types/interfaces';
import RetroSettings from './InnerComponents/RetroSettings.vue';
import BotSettings from './InnerComponents/BotSettings.vue';
import PanelSettings from './InnerComponents/PanelSettings.vue';
import CourseSkills from './InnerComponents/CourseSkills.vue';
import { IBot } from 'packages/components/types/interfaces';
import { isEmpty } from 'lodash';

const { t } = useI18n();

const props = defineProps({
  advancedSettings: {
    type: Object as () => IAdvancedSettings,
    required: true,
  },
  addons: {
    type: Array as () => IBot[],
    required: true,
  },
  panels: {
    type: Array as () => IPanel[],
    required: true,
  },
  retroState: {
    type: Object as () => Record<string, boolean>,
    required: true,
  },
  courseId: {
    type: String,
    default: '',
  },
  lessonId: {
    type: String,
    default: '',
  },
  resetRetroSections: {
    type: Boolean,
    required: false,
    default: false,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  showSkills: {
    type: Boolean,
    default: false,
  },
  courseNotInline: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'update:settingsModel',
  'update:retroState',
  'update:addons',
  'update:resetRetroSections',
  'update:panels',
  'update:showBotData',
  'update:showPanelData',
]);

const isNewCourse = computed(() => !props.courseId && !props.lessonId);
const isLesson = computed(() => !props.courseId && !isEmpty(props.lessonId));

const settingsModel = useVModel(props, 'advancedSettings', emit);
const retroState = useVModel(props, 'retroState', emit);
const addons = useVModel(props, 'addons', emit);
const resetRetroSections = useVModel(props, 'resetRetroSections', emit);
const panels = useVModel(props, 'panels', emit);

const filteredSkills = ref<string[]>([]);

const errorTracker = ref({
  botErrors: false,
  panelErrors: false,
});

const handleBotErrors = (value: boolean) => {
  errorTracker.value.botErrors = value;
};

const handlePanelErrors = (value: boolean) => {
  errorTracker.value.panelErrors = value;
};

const emitBotState = (value: boolean) => {
  emit('update:showBotData', value);
};

const emitPanelState = (value: boolean) => {
  emit('update:showPanelData', value);
};
</script>
