<template>
  <div class="lesson flex">
    <div class="grow" @mouseleave="emit('leave')">
      <div
        class="drag-handler bg-white py-2.5 px-4 flex justify-between items-center rounded-md shadow-md cursor-grab"
        @mouseover="emit('leave')">
        <div class="flex items-center gap-2">
          <div
            class="w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center">
            <BookOpen :size="13" class="text-secondary-900" />
          </div>
          <EditableField
            :empty="!el.title.length"
            class="h-6 flex items-center p-2"
            :class="{ 'pointer-events-none': !canEdit }">
            <template #display>
              <p class="select-none">{{ el.title }}</p>
            </template>
            <template #content="{ onCloseCallback }">
              <input
                v-model="el.title"
                class="content body min-w-20 w-fit !m-0 !p-0"
                autofocus
                @blur="applyChanges(onCloseCallback)"
                @keyup.enter="applyChanges(onCloseCallback)"/>
            </template>
          </EditableField>
        </div>
        <div class="flex items-center gap-1.5">
          <button
            v-tooltip.top="
              openFilesDisabled
                ? $t('course_settings.save_before_upload')
                : $t('course_settings.add_lesson_files')
            "
            :disabled="openFilesDisabled"
            :class="{ 'opacity-50 cursor-not-allowed': openFilesDisabled }"
            @click="$emit('open-files')">
            <div>
              <UploadCloud
                :size="16"
                :class="{ 'stroke-secondary-400': openFilesDisabled }"/>
            </div>
          </button>

          <PVSplitButton
            v-if="(canEdit && canRemoveLesson) || isSettingsOpen"
            ref="settings"
            :model="actions"
            :pt="iconDropdownPT">
            <template #menubuttonicon>
              <div @click="isSettingsOpen = !isSettingsOpen">
                <EllipsisVertical :size="16" />
              </div>
            </template>
          </PVSplitButton>
        </div>
      </div>
      <Transition name="fade-in">
        <div v-if="showAddUnit" class="relative h-10">
          <AddUnit @click="emit('addUnit')" />
          <div class="absolute w-full -bottom-2 h-2"></div>
        </div>
      </Transition>
    </div>
  </div>
  <DeleteLessonConfirmation
    v-model:visible="isConfirmationOpen"
    :lesson="element.title"
    @confirm="emit('removeLesson')"/>
</template>
<script setup lang="ts">
import { BookOpen, EllipsisVertical, UploadCloud } from 'lucide-vue-next';
import EditableField from '/@/views/Lobby/CourseSettings/EditableField.vue';
import AddUnit from '/@/views/Lobby/CourseSettings/partials/AddUnit.vue';
import { useVModel } from '@vueuse/core/index';
import { iconDropdownPT } from '/@/views/Lobby/styles';
import PVSplitButton from 'primevue/splitbutton';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import DeleteLessonConfirmation from '/@/views/Lobby/CourseSettings/partials/DeleteLessonConfirmation.vue';

const emit = defineEmits([
  'leave',
  'removeLesson',
  'updateModels',
  'addLesson',
  'addUnit',
  'open-files',
]);

const props = defineProps({
  element: {
    type: Object,
    default: () => ({
      title: '',
    }),
  },
  isHovered: {
    type: Boolean,
    default: false,
  },
  isDragged: {
    type: Boolean,
    default: false,
  },
  canRemoveLesson: {
    type: Boolean,
    default: false,
  },
  showAddUnit: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  openFilesDisabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const el = useVModel(props, 'element');
const settings = ref();
const isSettingsOpen = ref(false);
const isConfirmationOpen = ref(false);
const applyChanges = callback => {
  callback();
  emit('updateModels');
};

const actions = [
  {
    label: t('course_settings.remove_lesson'),
    command: () => {
      isConfirmationOpen.value = true;
      // emit('removeLesson')
    },
  },
];

onClickOutside(settings, () => {
  isSettingsOpen.value = false;
});
</script>
<style scoped>
.fade-in-enter-active {
  transition:
    height 0.4s ease,
    opacity 0.8s ease;
}
.fade-in-leave-active {
  transition:
    height 0.8s ease,
    opacity 0.4s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
  height: 0;
}
</style>
