<template>
  <div>
    <div
      class="cursor-pointer border border-secondary-250 max-h-10 max-w-10 p-2 rounded-lg bg-secondary-100 hover:bg-secondary-100"
      @click="togglePanel">
      <template v-if="selectedIconComponent">
        <component
          :is="selectedIconComponent"
          class="w-5 h-5 stroke-[1.14px]"/>
      </template>
      <template v-else>Select Icon</template>
    </div>

    <PVOverlayPanel ref="op">
      <div class="grid grid-cols-3 gap-2 p-2">
        <div
          v-for="icon in icons"
          :key="icon.name"
          class="cursor-pointer border-1 border-solid border-secondary-250 p-2 rounded-lg bg-secondary-100 flex justify-center items-center hover:bg-secondary-300 active:bg-primary-300"
          @click="selectIcon(icon.name)">
          <component :is="icon.component" class="stroke-secondary-900" />
        </div>
      </div>
    </PVOverlayPanel>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import PVOverlayPanel from 'primevue/overlaypanel';

import {
  Calculator,
  Pencil,
  Book,
  Map,
  Menu,
  Presentation,
  Search,
  Calendar,
  Play,
} from 'lucide-vue-next';

const icons = [
  { name: 'Calculator', component: Calculator },
  { name: 'Pencil', component: Pencil },
  { name: 'Book', component: Book },
  { name: 'Map', component: Map },
  { name: 'Menu', component: Menu },
  { name: 'Presentation', component: Presentation },
  { name: 'Search', component: Search },
  { name: 'Calendar', component: Calendar },
  { name: 'Play', component: Play },
];

const props = defineProps({
  icon: {
    type: String,
    default: 'Book',
  },
});

const selectedIcon = ref(props.icon);

const selectedIconComponent = computed(() => {
  return (
    icons.find(icon => icon.name === selectedIcon.value)?.component || null
  );
});

const op = ref(null);

const emit = defineEmits(['update']);

function togglePanel(event) {
  op.value.toggle(event);
}

function selectIcon(iconName) {
  emit('update', iconName);
  op.value.hide();
}

watch(
  () => props.icon,
  newIcon => {
    selectedIcon.value = newIcon;
  },
);

onMounted(() => {
  emit('update', selectedIcon.value);
});
</script>

<style scoped>
.grid {
  max-width: 300px;
}
</style>
